import useForm, { FormChange, SubmitPromise } from "@dashboard/hooks/useForm";
import useHandleFormSubmit from "@dashboard/hooks/useHandleFormSubmit";
import React from "react";

export interface SignupFormData {
  name: string;
  description: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface UseSignupFormResult {
  change: FormChange;
  data: SignupFormData;
  submit: () => SubmitPromise;
}

export interface SignupFormProps {
  children: (props: UseSignupFormResult) => React.ReactNode;
  onSubmit: (data: SignupFormData) => SubmitPromise;
}

const getSignupFormData = (): SignupFormData => ({
  name: "",
  description: "",
  email: "",
  firstName: "",
  lastName: "",
  password: "",
});

function useSignupForm(onSubmit: (data: SignupFormData) => SubmitPromise): UseSignupFormResult {
  const form = useForm(getSignupFormData());
  const { change, data } = form;
  const handleFormSubmit = useHandleFormSubmit({ onSubmit });
  const submit = async () => handleFormSubmit(data);

  return {
    change,
    data,
    submit,
  };
}

const SignupForm: React.FC<SignupFormProps> = ({ children, onSubmit }) => {
  const props = useSignupForm(onSubmit);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Cypress tests blow up without it
    event.preventDefault();
    props.submit();
  };

  return <form onSubmit={handleSubmit}>{children(props)}</form>;
};

SignupForm.displayName = "SignupForm";
export default SignupForm;

import { UserContextError } from "@dashboard/auth/types";
import { FormSpacer } from "@dashboard/components/FormSpacer";
import { useCreateStoreMutation } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import { getAppMountUriForRedirect } from "@dashboard/utils/urls";
import { CircularProgress, TextField } from "@material-ui/core";
// import { EyeIcon, IconButton } from "@saleor/macaw-ui";
import { Box, Button, Text } from "@saleor/macaw-ui-next";
import React from "react";
import urlJoin from "url-join";

import { useUser } from "../../";
import { DashboardPath } from "../../urls";
import useStyles from "../styles";
import { loginCallbackPath } from "../urls";
import SignupForm, { SignupFormData } from "./form";
// import { getErrorMessage } from "./messages";

export interface RegisterPageProps {
  errors: UserContextError[];
  disabled: boolean;
  loading: boolean;
  onSubmit: (event: SignupFormData) => SubmitPromise;
}

const RegisterPage: React.FC<RegisterPageProps> = props => {
  const { disabled, loading } = props;
  const classes = useStyles(props);
  const [showPassword] = React.useState(false);
  const [errors, setErrors] = React.useState<string | string[] | undefined>();
  const navigate = useNavigator();
  const { login } = useUser();
  const [createStore, createStoreOpts] = useCreateStoreMutation({
    onCompleted: async data => {
      if (data?.createStore?.store) {
        // Perform login after successful signup
        const loginResult = await login!(formData.email, formData.password);

        if (!loginResult?.errors?.length) {
          navigate(DashboardPath);
        } else {
          setErrors("Login failed after signup. Please try logging in manually.");
        }
      } else {
        setErrors("An unexpected error occurred");
      }
    },
  });
  const [formData, setFormData] = React.useState<SignupFormData>({
    name: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    description: "",
  });

  const handleSubmit = async (data: SignupFormData) => {
    setFormData(data);

    try {
      await createStore({
        variables: {
          name: data.name,
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          description: data.description,
        },
      });
      // The onCompleted callback will handle the success case
    } catch (error) {
      // setErrors("An error occurred while creating the store");
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={128} />
      </div>
    );
  }

  return (
    <SignupForm onSubmit={handleSubmit}>
      {({ change: handleChange, data, submit }) => (
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
          <Text size={6} fontWeight="bold" lineHeight={3} marginBottom={4}>
            Sign Up
          </Text>
          {errors && (
            <Box
              borderRadius={4}
              padding={4}
              backgroundColor="critical1"
              width="100%"
              marginBottom={2}
              data-test-id="login-error-message"
            >
              <Text>{errors}</Text>
            </Box>
          )}
          <TextField
            fullWidth
            label="Name"
            name="name"
            onChange={handleChange}
            value={data.name}
            inputProps={{
              "data-test-id": "name",
            }}
            disabled={disabled}
          />
          <FormSpacer />
          <TextField
            fullWidth
            label="Description"
            name="description"
            onChange={handleChange}
            value={data.description}
            inputProps={{
              "data-test-id": "description",
            }}
            disabled={disabled}
          />
          <FormSpacer />
          <TextField
            fullWidth
            autoComplete="email"
            label="Email"
            name="email"
            onChange={handleChange}
            value={data.email}
            inputProps={{
              "data-test-id": "email",
              spellCheck: false,
            }}
            disabled={disabled}
          />
          <FormSpacer />
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            onChange={handleChange}
            value={data.firstName}
            inputProps={{
              "data-test-id": "firstName",
            }}
            disabled={disabled}
          />
          <FormSpacer />
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            value={data.lastName}
            inputProps={{
              "data-test-id": "lastName",
            }}
            disabled={disabled}
          />
          <FormSpacer />
          <div className={classes.passwordWrapper}>
            <TextField
              fullWidth
              autoComplete="password"
              label="Password"
              name="password"
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              value={data.password}
              inputProps={{
                "data-test-id": "password",
                spellCheck: false,
              }}
              disabled={disabled}
            />
            {/* Not using endAdornment as it looks weird with autocomplete */}
            {/* <IconButton
              className={classes.showPasswordBtn}
              variant="ghost"
              hoverOutline={false}
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
            >
              <EyeIcon />
            </IconButton> */}
          </div>
          <div className={`${classes.buttonContainer}`}>
            <Button
              width="100%"
              disabled={disabled || createStoreOpts.loading}
              variant="primary"
              onClick={submit}
              type="submit"
              data-test-id="submit"
              marginTop={5}
            >
              Sign up
            </Button>
          </div>
        </Box>
      )}
    </SignupForm>
  );
};

RegisterPage.displayName = "RegisterPage";
export default RegisterPage;
